.cls-1{fill:#ffd300;}
.cls-2{fill:#f19001;}
.cls-3{fill:none;}
.cls-4{fill:#bd1823;}
.cls-5{fill:#e42313;}
.cls-6{fill:#0070ba;}
.cls-7{fill:#fff;}
.cls-8{fill:#37a6de;}
.cls-9{fill:#1d1e1c;}
.cls-10{fill:#1c5e96;}
.cls-11{fill:#e71e6c;}
.cls-12{fill:#e6533a;}
.cls-13{fill:#9b9a9a;}

:global(#Totem):enabled{
    .main g:hover{
        filter: drop-shadow(0 0 10px white);
        stroke:white;
        stroke-width: 2;
        stroke-opacity: 0.6;
    }
}


.main g.select{
    filter: drop-shadow(0 0 10px white);
    stroke:white;
    stroke-width: 2;
    stroke-opacity: 0.6;
}
.main g{
    cursor:pointer;
}
:global(#doigt-pied-droit):hover{
    filter: none;
    stroke:none;

}

:global(#doigt-pied-gauche):hover{
    filter: none;
    stroke:none;

}

:global(#tete):hover{
    filter: none;
    stroke:none;
}


