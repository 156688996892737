.Login{
    background-image: url('/src/assets/images/bg.jpg');
    height: 100vh;
    //width: 100vw;
    background-size: cover;
  }
  
  .From{
    background-color: #192e4e;
    border-radius: 20px;
    align-items: center;
    padding: 15px;
    height: 10em;
  }
  
  .From{
    background-color: #192e4e;
    border-radius: 20px;
    align-items: center;
    padding: 15px;
    height: 10em;
  }
  .From .field{
    background-color: #0C5788;
    padding: 1em 2em;
    border-radius: 20px;
    justify-content: space-evenly;
  }
  
  h2{
    margin-bottom:1em;
  }
  .label{
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    padding: 5px 10px;
    color: white;
    border-radius: 50px;
    font-size: 20px;
    text-align: center;
    margin: 0.75em 0;
    margin-top: 0.25em;
      }
  .From .field h2 {
    color: white;
    margin-bottom: 0;
  }
  
  
  