.cards{
  text-align: center;
  background-color: white;
  padding: 1em;
  border-radius: 25px;
  margin: 0 auto;
  height: 100%;
  max-width: 350px;
  min-width: 200px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.cards.cardsSelected{
  background-color: #458bc9;
  justify-content: normal;
}

.image{
  content: " ";
  //background-image: url(/0573df0….png);
  width: 250px;
  height: 146px;
  left: 50%;
  top: -146px;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateX(-50%);
}

.cards h4{
  width: 100%;
  color: white;
  border-radius: 50px;
  padding: 0.5em;
  font-size: 20px;
  background-color: #0C5788 ;
}

.cards p{
  color: #595959;
  font-weight: 500;
  text-align: left;
  padding: 10px;
  margin-bottom: 1.75em;
  margin-top: 0;
}

.cards.cardsSelected p{
  color: white;
}


.cards button{
  background-color: #458bc9;
  width: fit-content;
  padding: 10px 20px;
  border-radius: 50px;
  color: #fff;
  transition: all 0.5s;
  font-weight: 500;
  margin: 0;
}

.cards.cardsSelected button{
  display: none;
}

.cards button:hover {
  background-color: #0C5788;
  cursor: pointer;
}
