.Adjectif > p {
  display: block;
  text-align: left;
  font-size: 18px;
  margin-left: 30px;
  margin-bottom: 10px;
  color: white;
  filter: drop-shadow(0 0 0.75rem white);
}

.inputTags {
  padding: 15px;
  border-radius: 20px;
  width: 95%;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.8);
}

.inputTags div {
  background-color: transparent;
  border: none;
}

.inputTags div input{
  color: #002E52;
}
.inputTags div div {
  color: white;
  background-color: #1a5ba7;
}
.inputTags div div::after, .inputTags div div ::before {
  background-color: white;
}
.buttonTag {
  width: 95%;
  margin-left: 30px;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.buttonTag > p {
  margin-bottom: 0;
  margin-right: 20px;
  font-size: 16px;
}

.buttonTag button {
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  display: flex;
  align-items: center;
}
.buttonTag button + button {
  margin-left: 10px;
}
.buttonTag button img {
  height: 30px;
  width: auto;
}
.buttonTag button p {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 0;
}

.aideZone img {
  height: 30px;
  width: auto;
}

//.modalContent {
//  background-color: #002e52 !important;
//  color: white !important;
//  border-radius: 25px !important;
//}
//
//.modalHeader button {
//  background-color: white !important;
//}
