.modal-dialog .modal-content {
  background-color: #002e52;
  color: white;
  border-radius: 25px;
  .modal-header button {
    background-color: white;
  }
}

.wys-card {
  display: flex;
  justify-content: space-around;

  &__subTitle {
    padding: 1rem 1rem 1.5rem 1rem;

    font-size: 1.25rem;
    font-weight: bold;
    text-transform: uppercase;

    &--red {
      color: red;
    }

    &--yellow {
      color: yellow;
    }

    &--blue {
      color: blue;
    }
  }

  &__categorie {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.2);
    height: 65vh;
    overflow-y: auto;
    width: 220px;
  }

  &__items {
    // color: white;
    font-weight: 600;
    text-transform: uppercase;

    p {
      padding: 0.1rem 0.2rem;
      border-radius: 2rem;
    }

    p:hover {
      cursor: pointer;
    }

    &--red {
      .checked {
        background: red;
      }
    }

    &--yellow {
      .checked {
        color: black;
        background: yellow;
      }
    }

    &--blue {
      .checked {
        background: blue;
      }
    }
  }
}

// .checked {

//   border-radius: 2rem;
//   background: red;

// }

// .container {
//   background: rgba(255, 255, 255, 0.3);
//   box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
//   backdrop-filter: blur(14px);
//   -webkit-backdrop-filter: blur(14px);
//   border-radius: 10px;
//   border: 1px solid rgba(255, 255, 255, 0.18);

// }

// .qualite,.valeurs,.habilites {
//   background: rgba(255, 255, 255, 0.8);
//   box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
//   backdrop-filter: blur(14px);
//   -webkit-backdrop-filter: blur(14px);
//   border-radius: 10px;
//   border: 1px solid rgba(255, 255, 255, 0.18);
//   text-align: center;
// }

// .container_ressources{
//     display: flex;
//     flex-direction: row;
//     justify-content: space-evenly;
// }

// .color-red{
//   color:rgb(255, 0, 0);
// }

// .color-yellow{
//   color:rgb(255, 238, 0);
// }

// .color-blue{
//   color:blue;
// }

// .big-size{
//   font-size: 1.5em;
// }

// .checked{
//   text-decoration: underline;
//   text-decoration-color: rgb(255, 0, 157);

// }

// .ressources-white{
//   color: white;
// }

// .centered {
//   height: 100vh; /* Magic here */
//   display:flex;
//   flex:column;
//   justify-content: center;
//   align-items: center;
// }
