:global(#container-main):has(.Debriefing){
  justify-content: flex-start!important;

}

.reviewTitle {
  color: white;
  padding: 15px 0;
}

.Debriefing .savebutton {
  button,a{
  width: fit-content;
  padding: 10px 20px;
  border: none;
  background-color: #0C5788;
  color: white;
  border-radius: 50px;
  margin: 30px 0 30px;
  transition: all 0.5s;
  font-size: 20px;
}
}

.Debriefing .savebutton {
  button:hover,a:hover{
  background-color: #fff;
  color: #0C5788;
  }
}

.review {
  width: 90%;
  margin: 0 auto;
}

.review .answerBlock {
  padding: 0 25px 25px;
  border-radius: 50px;
}

.titre{
  margin: 0.2rem 0 1rem;
  text-align: left;
  color: #fff;
}

.review .answerBlock .answerFlex {
  display: flex;
  justify-content: space-around;
}

.card p {
  color: #595959;
  text-align: left;
}

.card {
  background-color: #fff;
  width: 25%;
  padding: 15px;
  border-radius: 25px;
}

.questionTitle {
  padding: 10px;
  font-size: 20px;
  border-radius: 50px;
  color: white;
  background-color: #458bc9;
  text-align: center;
}
.Reponse label {
  display: block;
  text-align: left;
  font-size: 18px;
  margin-left: 300px;
  margin-bottom: 10px;
  color: white;
  filter: drop-shadow(0 0 0.75rem white);
}

.Reponse textarea{
  border: none;
  padding: 20px;
  font-size: 16px;
  border-radius: 20px;
  width: 95%;
  color: #002E52;
  background-color: rgba(255, 255, 255, 0.8);
  min-height:6em!important;
}
textarea::placeholder{
  color: #002E52;
}

:global(.accordion-body){
  background-color: #002E52;
}
:global(.accordion-header){
  background-color: #002E52;
  margin-bottom:0px;
}
:global(.accordion-item){
  background-color: #002E52;
  border-color:#002E52!important ;
}

