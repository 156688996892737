.Other-User {
color:red;
}

.connected {
    color:green;
    }

    .connect{
        margin-bottom:10px;
    }