.btn{
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 150px;
  background-color: transparent;
  border: none;
  color: white;
}

.btn[disabled] {
  opacity: 0.5;
  cursor: default;
}

.img-btn{
  width: 50px;
  height: 50px;
  vertical-align: middle;
}

.img-left {
  transform: rotate(-180deg);
}
