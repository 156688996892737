.SelectGame{
  //position: absolute;
  //top: 40%;
}


.btnValider{
  border-radius: 50px;
  border: none;
  margin-top: 3.5em;
  padding: 10px 20px;
  //width: 300px;
  background-color: #0C5788;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  transition: 0.5s all ease-in-out;
}

.btnValider:hover {
  background-color: #fff;
  color: #0C5788;
}

.btnValider:disabled:hover {
  background-color: #0C5788!important;
  color: #fff!important;
  opacity: 0.33!important;
}
.btnValider:disabled {
  background-color: #0C5788!important;
  color: #fff!important;
  opacity: 0.33!important;
}
