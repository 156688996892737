.Game{
  max-width: 100vw;
  min-height: 100vh;
}
.Interface{
  max-width: 600px;
}

.form{
  margin-top: 40px;
  overflow: hidden;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0 !important;
}

.form-question{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.2);
}

.form-question p{
  margin-bottom: 0;
  margin-left: 10px;
  font-size: 25px;
  font-weight: bold;
  color: white;
}

.form-question img{
  width: 70px;
  cursor: pointer;
  vertical-align: middle;
  border-style: none;
}

.form-main {
  display: flex;
}

.imgTotem{
  width: 15%;
  margin: 20px auto;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  padding: 20px;
  vertical-align: middle;
}

input:disabled {
  opacity: 0.5;
}

fieldset:disabled label{
  opacity: 0.5;
}

fieldset:disabled svg{
  opacity: 0.5;
}
