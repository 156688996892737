.Reponse{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 80%;
  margin-left: 2em;
}
input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #002E52;
  opacity: 1; /* Firefox */
}
