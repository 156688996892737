main {
  background-color: #002e52;
  color: white;
  max-width: 100vw;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

}

.app{
  padding: 0 !important;
  max-width: 100vw;
  min-height: 100vh;
}

