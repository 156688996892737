.home{
  padding:3em 0 0 0!important;
  background-image: url('/src/assets/images/astronaut.png');
  background-size: 28em;
  //background-clip: padding-box;
  background-repeat: no-repeat;
  background-position: top 5em right;
}

/* Règle CSS pour les écrans larges */
//.btnCode {
//  font-size: 16px;
//  padding: 10px 20px;
//}

/* Règle CSS pour les écrans étroits */
@media screen and (max-width: 767px) {
  .home .btnCode {
    font-size: 0; /* masque le texte */
  }

  /* Utilisation d'une police d'icônes */
  .home .btnCode::before {
    content: "\2713"; /* code Unicode pour l'icône */
    font-weight: 900;
    font-size: 20px;
    color: #fff;
    line-height: 1;
  }
}

.title{
  padding-top: 5em;
  padding-left: 12em;
}

.title h2{
  font-size: 50px;
  font-weight: 800;
}

.title p{
  text-align: left;
  font-size: 40px;
  font-weight: 300;
  height: 100%;
}


.login{
  //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 70 500 60' preserveAspectRatio='none'%3E%3Crect x='0' y='0' width='500' height='500' style='stroke: none; fill: %23FFFFFF;' /%3E%3Cpath d='M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z' style='stroke: none; fill: %23002e52;'%3E%3C/path%3E%3C/svg%3E");
  background-image: url('/src/assets/images/wave_adobe_express.svg');
  background-size: 101% auto;
  //background-clip: padding-box;
  background-repeat: no-repeat;
  background-position: bottom center;
  padding-bottom: 8em;
}

.login h3{
  margin-top: 3em;
  font-size: 1.75rem;
}

.login a{
  width: 200px;
  margin: 20px;
  box-shadow: 0 0 10px -1px rgb(255 255 255 / 20%);
}

.infos{
  background-color: white;
  color: #595959;
}

.infosProjet{
  background-color: #EDEBEB;
  padding: 15px;
  border-radius: 25px;
  margin-bottom: 7em;
}

.infosProjet h4{
  font-size: 20px;
  font-weight: bolder;
  line-height: 3em;
}

.code{
  display: flex;
  justify-content: center;
  align-items: center;
}

.subMenu {
  position: absolute;
  background-color: #EDEBEB;
  top: 15px;
  right: 15px;
  padding: 20px 30px;
  width: 20%;
  border-radius: 25px;
  z-index: 5;
}
.burger{
  width: 20%;
  border-radius: 10px;
  background-color: #07273c;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin : 2%;
}

.burger .click {
  width: fit-content;
  padding: 10px 20px;
  border: none;
  background-color: #0c5788;
  color: white;
  border-radius: 50px;
  margin: 15px 0 0;
  transition: all 0.5s;
  font-size: 20px;
}

