.cards{
  background-color: #EDEBEB;
  padding: 1em;
  border-radius: 25px;
  margin: 0 5em;
  height: 100%;
}

.cards h4{
  color: white;
  border-radius: 50px;
  padding: 0.5em;
  font-size: 20px;
}

.cards img {
  margin: 1em 0;
  border-radius: 25px;
}

.cards p{
  color: #595959;
  font-weight: 500;
  text-align: left;
  padding: 10px;
}
