.Login{
  background-image: url('/src/assets/images/bg.jpg');
  height: 100vh;
  //width: 100vw;
  position: relative;
  background-size: cover;
}



.From{
  background-color: #192e4e;
  border-radius: 20px;
  align-items: center;
  padding: 15px;
  height: 10em;
}

.From .field {
  background-color: #0C5788;
  padding: 1em 2em;
  border-radius: 20px;
  justify-content: space-evenly;
}

.From .field h2 {
  color: white;
  margin-bottom: 0;
}

.From .field input {
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  padding: 5px 10px;
  color: #002E52;
  border-radius: 50px;
  font-size: 20px;
  text-align: center;
  margin: 0.75em 0;
}

.From .field input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #002E52;

  opacity: 0.7; /* Firefox */

}


.From .click {
  width: fit-content;
  padding: 10px 20px;
  border: none;
  background-color: #0c5788;
  color: white;
  border-radius: 50px;
  margin: 15px 0 0;
  transition: all 0.5s;
  font-size: 20px;
}

