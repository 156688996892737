
  .title h2{
    font-size: 50px;
    font-weight: 800;
  }
  
  .title p{
    text-align: left;
    font-size: 40px;
    font-weight: 300;
    height: 100%;
  }
  