.Reponse label {
  display: block;
  text-align: left;
  font-size: 18px;
  margin-left: 30px;
  margin-bottom: 10px;
  color: white;
  filter: drop-shadow(0 0 0.75rem white);
}

.Reponse input{
  border: none;
  padding: 20px;
  font-size: 16px;
  border-radius: 20px;
  width: 95%;
  color: #002E52;

  background-color: rgba(255, 255, 255, 0.8);
}

