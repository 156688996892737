@import "bootstrap";
.row{
  margin:0;
}
@import '@pathofdev/react-tag-input/build/index.css';

//StepBar
:where(.css-dev-only-do-not-override-1km3mtt).ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: white;
  border-color: transparent;
}

:where(.css-dev-only-do-not-override-1km3mtt).ant-steps .ant-steps-item-title::after {
  background: #fff;
}

:where(.css-dev-only-do-not-override-1km3mtt).ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background: #fff;
}

:where(.css-dev-only-do-not-override-1km3mtt).ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background: #fff;
}



.ant-steps-item-custom .ant-steps-icon {
  font-size: 16px !important;
}
.ant-steps-item-custom:nth-child(2) .ant-steps-item-icon,
.ant-steps-item-custom:nth-child(3) .ant-steps-item-icon,
.ant-steps-item-custom:nth-child(4) .ant-steps-item-icon,
.ant-steps-item-custom:nth-child(5) .ant-steps-item-icon,
.ant-steps-item-custom:nth-child(6) .ant-steps-item-icon,
.ant-steps-item-custom:nth-child(7) .ant-steps-item-icon,
.ant-steps-item-custom:nth-child(8) .ant-steps-item-icon,
.ant-steps-item-custom:nth-child(9) .ant-steps-item-icon,
.ant-steps-item-custom:nth-child(10) .ant-steps-item-icon {
  //background-color: #fff !important;
  width: 32px !important;
}
.ant-steps-item-finish .anticon-check {
  display: block !important;
  margin-top: 10px !important;
  color: #1890ff
}
.ant-steps-item-finish .step_icon {
  display: none !important;
}
.ant-steps-item-icon {
  height: 32px !important;
}
.ant-steps-item:last-child .ant-steps-item-icon {
  height: auto !important;
}

.ant-steps-item .anticon-check {
  display: none;
}
.ant-steps-item-process
> .ant-steps-item-container
> .ant-steps-item-icon
.ant-steps-icon {
  color: #1890ff !important;
}

.ant-steps-icon {
  color: white !important;
  font-weight: bold;
}

//modal



.modal-content {
  background-color: #002e52 !important;
  color: white !important;
  border-radius: 25px !important;
}

.modal-header button {
  background-color: white !important;
}
