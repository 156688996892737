.Satisfaction {
  display: flex;
  margin-top: 10px;
}
.Satisfaction p {
  display: block;
  text-align: left;
  font-size: 18px;
  margin-left: 30px;
  margin-bottom: 10px;
  color: white;
  filter: drop-shadow(0 0 0.75rem white);
}
.radioGroup {
  width: 80%;
}

.radioGroup > label {
  margin: auto 10px;
  background-color: rgba(255, 255, 255, 0.4);
  color: white;
}
.radioGroup:hover span{
  color:white;
}
.radioGroup-red :global(.ant-radio-button-wrapper-checked) {
 background-color:#E42312!important;
 border-color:#E42312!important;
}
.radioGroup-red :global(.ant-radio-button-wrapper-checked)::before {
  background-color:#E42312!important;
  border-color:#E42312!important;
 }