.searchTab td{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.searchTab th.selected::after{
  content : "\0025B2";
  display: inline-block;
  font-size: .8rem;
  vertical-align: middle;
}

.searchTab th.selected.desc::after{
  content : "\0025BC";
}

.searchTab td{
  cursor: pointer;
}

.table-custom {
  background-color: #5c7991;
  color: #fff; /* Texte blanc */
}

.table-custom tr{
  color: #fff;
}
